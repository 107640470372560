import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, RouteReuseStrategy, RouterModule } from '@angular/router';
import { UserGuard } from '@app/core/guards/user.guard';
import { FdxRouteReuseStrategy } from './route-reuse-strategy.class';

/**
 * The user could have an old version of the application loaded in
 * their browser after a deployment has happened.
 * They could click on a lazy-loaded route then the app will fail
 * to load the js chunk file because it has been deleted from the
 * server.
 * When this happens, reload the browser page automatically so that
 * the user immediately gets the latest version of the app.
 * @param e error
 */
function onImportFail(e: unknown): never {
    // eslint-disable-next-line no-console
    console.error('[AppRoutingModule] Import module failed:', e);

    if (e instanceof Error && e.name === 'ChunkLoadError') {
        let reload = true;

        const key = 'last-import-chunk-failure-reload';
        const lastReload = sessionStorage.getItem(key);
        const now = Date.now();

        if (lastReload) {
            const epoch = parseInt(lastReload);
            const diffMS = now - epoch;

            // prevent infinite reload loop on dev servers
            // do not reload if it has been done in the last 10 seconds
            if (diffMS < 10000) {
                reload = false;
            }
        }

        if (reload) {
            sessionStorage.setItem(key, now.toString());

            // @ts-expect-error https://developer.mozilla.org/en-US/docs/Web/API/Location/reload - Pass true to force a reload bypassing the cache. Defaults to false. Only supported in Firefox.
            location.reload(true);
        }
    }

    throw e;
}

/**
 * Contains app level routes which are lazy loaded for performance.
 * Each of these routes should declare their own `UserGuard`.
 * Other guards which may require `UserGuard` to be completed first
 * can be declared in the feature module's routing module instead.
 */
export const routes: Route[] = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'databases'
    },
    {
        path: 'accounts',
        loadChildren: () => import(/* webpackChunkName: "accounts" */ './accounts/accounts.module').then((m) => m.AccountsModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: 'databases',
        loadChildren: () => import(/* webpackChunkName: "databases" */ './databases/databases.module').then((m) => m.DatabasesModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: 'utilities',
        loadChildren: () => import(/* webpackChunkName: "utilities" */ './utilities/utilities-module').then((m) => m.UtilitiesModule ).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: 'keyboard-shortcuts',
        loadChildren: () => import(/* webpackChunkName: "keyboard-shortcuts" */ './keyboard-shortcuts/keyboard-shortcuts.module').then((m) => m.KeyboardShortcutsModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: 'keyboard_shortcuts',
        redirectTo: 'keyboard-shortcuts'
    },
    {
        path: ':account_id/databases',
        loadChildren: () => import(/* webpackChunkName: "databases" */ './databases/databases.module').then((m) => m.DatabasesModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/imports',
        loadChildren: () => import(/* webpackChunkName: "imports" */ './imports/imports.module').then((m) => m.ImportsModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/data',
        loadChildren: () => import(/* webpackChunkName: "data" */ './data/data.module').then((m) => m.DataModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/data-new',
        redirectTo: ':database_id/data'
    },
    {
        path: ':database_id/transformed_data',
        redirectTo: ':database_id/data'
    },
    {
        path: ':database_id/transformed_data_ebay',
        redirectTo: ':database_id/data'
    },
    {
        path: ':database_id/data-overrides',
        loadChildren: () => import(/* webpackChunkName: "data-overrides" */ './data-overrides/data-overrides.module').then((m) => m.DataOverridesModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/transformed_data_overrides',
        redirectTo: ':database_id/data-overrides'
    },
    {
        path: ':database_id/channel-listings',
        loadChildren: () => import(/* webpackChunkName: "channel-listings" */ './channel-listings/channel-listings.module').then((m) => m.ChannelListingsModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/error-resolution',
        loadChildren: () => import(/* webpackChunkName: "error-resolution" */ './error-resolution/error-resolution.module').then((m) => m.ErrorResolutionModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/error_resolution_data',
        redirectTo: ':database_id/error-resolution'
    },
    {
        path: ':database_id/exports',
        loadChildren: () => import(/* webpackChunkName: "exports" */ './exports/exports.module').then((m) => m.ExportsModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/export-runs',
        loadChildren: () => import(/* webpackChunkName: "export-runs" */ './export-runs/export-runs.module').then((m) => m.ExportRunsModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/error-overview',
        loadChildren: () => import(/* webpackChunkName: "error-overview" */ './error-overview/error-overview.module').then((m) => m.ErrorOverviewModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/error_overview',
        redirectTo: ':database_id/error-overview'
    },
    {
        path: ':account_id/feedsupport-new',
        loadChildren: () => import(/* webpackChunkName: "feed-support" */ './feed-support/feed-support.module').then((m) => m.FeedSupportModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/file-maps',
        loadChildren: () => import(/* webpackChunkName: "file-maps" */ './file-maps/file-maps.module').then((m) => m.FileMapsModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/genai',
        loadChildren: () => import(/* webpackChunkName: "gen-ai" */ './gen-ai/gen-ai.module').then((m) => m.GenAiModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/store-summary-report',
        loadChildren: () => import(/* webpackChunkName: "store-summary-report" */ './store-summary-report/store-summary-report.module').then((m) => m.StoreSummaryReportModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/store_summary_report',
        redirectTo: ':database_id/store-summary-report'
    },
    {
        path: 'demos',
        loadChildren: () => import(/* webpackChunkName: "demos" */ './demos/demos.module').then((m) => m.DemosModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: 'users',
        loadChildren: () => import(/* webpackChunkName: "user-access-control" */ './user-access-control/user-access-control.module').then((m) => m.UserAccessControlModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: 'profile',
        loadChildren: () => import(/* webpackChunkName: "profile" */ './profile/profile.module').then((m) => m.ProfileModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':account_id/users',
        loadChildren: () => import(/* webpackChunkName: "user-access-control" */ './user-access-control/user-access-control.module').then((m) => m.UserAccessControlModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/automate-build',
        loadChildren: () => import(/* webpackChunkName: "automate-build" */ './automate-build/automate-build.module').then((m) => m.AutomateBuildModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: 'help',
        loadChildren: () => import(/* webpackChunkName: "help" */ './help/help.module').then((m) => m.HelpModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: 'cheat_sheet',
        redirectTo: 'help/cheat-sheet'
    },
    {
        path: ':database_id/export-random-sampling',
        loadChildren: () => import(/* webpackChunkName: "export-random-sampling" */ './export-random-sampling/exports-random-sampling.module').then((m) => m.ExportsRandomSamplingModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/export_random_sampling',
        redirectTo: ':database_id/export-random-sampling'
    },
    {
        path: ':database_id/summary-governance',
        loadChildren: () => import(/* webpackChunkName: "summary-governance" */ './summary-governance/summary-governance.module').then((m) => m.SummaryGovernanceModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/summary_governance',
        redirectTo: ':database_id/summary-governance'
    },
    {
        path: ':database_id/export-triggers',
        loadChildren: () => import(/* webpackChunkName: "export-triggers" */ './export-triggers/export-triggers.module').then((m) => m.ExportTriggersModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/export_triggers',
        redirectTo: ':database_id/export-triggers'
    },
    {
        path: ':database_id/category-taxonomy',
        loadChildren: () => import(/* webpackChunkName: "category-taxonomy" */ './category-taxonomy/category-taxonomy.module').then((m) => m.CategoryTaxonomyModule ).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/category_taxonomy',
        redirectTo: ':database_id/category-taxonomy'
    },
    {
        path: ':database_id/data-governance',
        loadChildren: () => import(/* webpackChunkName: "data-governance" */ './data-governance/data-governance.module').then((m) => m.DataGovernanceModule ).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/extra-fields',
        loadChildren: () => import(/* webpackChunkName: "extra-fields" */ './extra-fields/extra-fields.module').then((m) => m.ExtraFieldsModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/data_governance',
        redirectTo: ':database_id/data-governance'
    },
    {
        path: ':database_id/logs-new',
        loadChildren: () => import(/* webpackChunkName: "logs" */ './logs/logs.module').then((m) => m.LogsModule ).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/reports-authorization',
        loadChildren: () => import(/* webpackChunkName: "reports-authorization" */ './reports-authorization/reports-authorization.module').then((m) => m.ReportsAuthorizationModule ).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/reports_authorization',
        redirectTo: ':database_id/reports-authorization'
    },
    {
        path: ':database_id/vault',
        loadChildren: () => import(/* webpackChunkName: "vault" */ './vault/vault.module').then((m) => m.VaultModule ).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/ftp-triggers',
        loadChildren: () => import(/* webpackChunkName: "ftp-triggers" */ './ftp-trigger/ftp-trigger.module').then((m) => m.FtpTriggerModule ).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/ftp_triggers',
        redirectTo: ':database_id/ftp-triggers'
    },
    {
        path: ':database_id/alerts',
        loadChildren: () => import(/* webpackChunkName: "email-alerts" */ './email-alerts/email-alerts.module').then((m) => m.EmailAlertsModule ).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/ftp-account',
        loadChildren: () => import(/* webpackChunkName: "ftp-account" */ './ftp-account/ftp-account.module').then((m) => m.FtpAccountModule ).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/ftp_account',
        redirectTo: ':database_id/ftp-account'
    },
    {
        path: ':database_id/qa',    // feedgrader and feedqa
        loadChildren: () => import(/* webpackChunkName: "feedgrader" */ './feed-grader/feed-grader.module').then((m) => m.FeedGraderModule ).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/feedgrader',
        redirectTo: ':database_id/qa/feedgrader'
    },
    {
        path: ':database_id/feedqa',
        redirectTo: ':database_id/qa/feedqa'
    },
    {
        path: ':account_id/feed-alerts-dashboard',
        loadChildren: () => import(/* webpackChunkName: "feed-alerts-dashboard" */ './feed-alerts-dashboard/feed-alerts-dashboard.module').then((m) => m.FeedAlertsDashboardModule).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: 'billing',
        loadChildren: () => import(/* webpackChunkName: "billing" */ './billing/billing.module').then((m) => m.BillingModule ).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/map-field',
        loadChildren: () => import(/* webpackChunkName: "map-field" */ './map-field/map-field.module').then((m) => m.MapFieldModule ).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: ':database_id/map_field',
        redirectTo: ':database_id/map-field'
    },
    {
        path: 'release-notes',
        loadChildren: () => import(/* webpackChunkName: "release-notes" */ './release-notes/release-notes.module').then((m) => m.ReleaseNotesModule ).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: 'release_notes',
        redirectTo: 'release-notes'
    },
    {
        path: ':database_id/comments',
        loadChildren: () => import(/* webpackChunkName: "comments" */ './comments/comments.module').then((m) => m.CommentsModule ).catch(onImportFail),
        canActivate: [
            UserGuard
        ]
    },
    {
        path: '**',
        children: []
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            {
                enableTracing: false,
                useHash: true,
                preloadingStrategy: PreloadAllModules
            }
        )
    ],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: FdxRouteReuseStrategy
        }
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
