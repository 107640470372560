import * as angular from 'angular';
import { MODULE_NAME } from '../config/constants';

import './index.js';

angular.module(MODULE_NAME)
.controller('FeedsupportController', ['$scope', '$http', '$uibModal', '$location', '$state', '$stateParams', '$upload', '$timeout', 'AppStateService', 'UsersDataService', 'FeedSupportStateService', 'fdxUI', function($scope, $http, $uibModal, $location, $state, $stateParams, $upload, $timeout, AppStateService, UsersDataService, FeedSupportStateService, fdxUI) {

    fdxUI.setActiveTab('dashboards');
    fdxUI.setTitle('FeedSupport');
    $scope.$parent.active_tab = 'db_list';
    $scope.$parent.return_tab = '/feedsupport';
    $scope.$parent.title_text = 'FeedSupport';
    $scope.fs_navigation_tab = $stateParams['fs_navigation_tab'] || 'create_new_ticket';
    $scope.fs_ticket_number = $stateParams['fs_ticket_number'] || '';

    // feedsupportNavService

    $scope.get_fs_ticket_pending = () => {
        return $scope.fs_ticket_number === '0';
    };

    $scope.fs_ticket_forbidden=false;
    $scope.get_fs_ticket_forbidden = () => {
        if($scope.fs_ticket_number === '0'){
            return true;
        }
        else if($scope.fs_ticket_number !== ''){
            return FeedSupportStateService.getTicketForbidden();
        }
        return false;

    };

    $scope.$watch('fs_ticket_number', () => {
        FeedSupportStateService.setTicketForbidden(false);
    });

    $scope.accountId = AppStateService.getAccountId();
    $scope.feedSupportTicketsLink = `/app/#/${$scope.accountId}/feedsupport/tickets`;

    $scope.showVersionSwitchBanner = () => {
        return AppStateService.getUser().hasFeature('new_feedsupport', 'enabled')
            || AppStateService.getAccount().hasFeature('new_feedsupport', 'enabled');
    }

    $scope.goToNewFeedSupportPage =  () => {
        $state.go('app.feedsupport-new', { account_id: AppStateService.getAccountId() });
    }

}]);
