import * as angular from 'angular';
import { MODULE_NAME } from '../../../config/constants';

angular.module(MODULE_NAME)
.controller('FeedsupportViewTicket', ['$scope', '$state', '$http', '$location', '$stateParams', '$upload', '$timeout', 'AppStateService', 'FeedSupportStateService', 'fdxUI', 'ModalService', function($scope, $state, $http, $location, $stateParams, $upload, $timeout, AppStateService, FeedSupportStateService, fdxUI, ModalService) {

	$scope.account_id = AppStateService.getAccount().id;
	$scope.user = AppStateService.getUser();

    $scope.fs_navigation_tab = $stateParams['fs_navigation_tab'] || 'create_new_ticket';

    const newUI = $state.current?.data?.newUI;

    if (newUI) {
        $scope.fs_navigation_tab = 'tickets';
    }

    $scope.showTicketsLabel = () => {
        return !newUI;
    }

	$scope.fs_ticket_number = $stateParams['fs_ticket_number'] || '';

	$scope.feedsupport_navbar = {};
	$scope.feedsupport_navbar.account_ticket_access = AppStateService.getAccount().hasAccountLevelAccess();
	$scope.feedsupport_navbar.store_select_dropdown = [];
	$scope.feedsupport_navbar.default_load_all_stores = false;

	$scope.entities = {
		"db_groups":[],
		"db_group_populated":false
	};
	$scope.store_definition = "db_groups_only";

	$scope.ebay_minimal_ui_flag = fdxUI.showMinimalEbayUI();

	$scope.conversations = {};
	$scope.attachments = {};
	$scope.editors = {};
	$scope.pending_files = {};
	$scope.loading_conversations = {};
	$scope.show_form = {};
	$scope.pulling_tickets = false;
	$scope.params = {
		'status': ''
	};
	$scope.filters = {
		'relevant_id':0,
		'store':{},
		'creator':"All",
	};

    $scope.feedSupportTicketsLink = `/app/#/${$scope.account_id}/feedsupport/tickets`;

	$scope.default_sort_array=[
		{'parameter':'modifiedAt','reverse':true,'primary':true},
		{'parameter':'createdAt','reverse':true,'primary':false},
		{'parameter':'ticketNumber','reverse':true,'primary':false},
		{'parameter':'subject','reverse':true,'primary':false},
		{'parameter':'priority','reverse':true,'primary':false},
		{'parameter':'status','reverse':true,'primary':false}
	];

	$scope.ticket_pagination ={
		'tickets':[],
		'unfiltered_tickets':[],
		'current_page':0,
		'page_size':20,
		'end_of_list':false,
		'api_page':0,
		'final_modified_time':'',
		'ticket_search':'',
		'sort_array':angular.copy($scope.default_sort_array),
		'sort_primary':'modifiedAt',
		'sort_primary_reverse':true,
	}

	$scope.store_filter_dropdown = {
		'unfiltered_limit':100,
		'search_result_limit':100,
		'load_flag':false,
		'stores_list':[]
	}


	$scope.$watchCollection('params', function(newVal, oldVal) {
		if(newVal && newVal != oldVal) {
			$scope.ticket_nav_change_store_filter();
		}
	});

	$scope.$watch('fs_navigation_tab', function(tab) {
		FeedSupportStateService.setTicketForbidden(false);
		$scope.get_store_definition();
		if(tab !== 'create_new_ticket'){
			$scope.establish_entities();
		}
		else{
			$scope.unset_filter_stores();
		}
	});

	// Ticket navigation functions
	$scope.ticket_nav_change_store_filter = function () {
		if($scope.pulling_tickets == true){
			$timeout(function(){
				$scope.ticket_nav_change_store_filter();
			},500);
		}
		else{
			var preserve_filter = $scope.ticket_pagination.ticket_search;

			$scope.ticket_pagination ={
				'tickets':[],
				'unfiltered_tickets':[],
				'current_page':0,
				'page_size':20,
				'end_of_list':false,
				'api_page':0,
				'final_modified_time':'',
				'ticket_search':preserve_filter,
				'sort_array':angular.copy($scope.default_sort_array),
				'sort_primary':'modifiedAt',
				'sort_primary_reverse':true,
			}

			$scope.load_tickets(1);
		}


	}

	$scope.ticket_nav_change_sort_order = function(sort_by) {
		var reverse = true;
		var index = $scope.ticket_pagination.sort_array.length-1;
		while (index >= 0) {
			var sort_logic = $scope.ticket_pagination.sort_array[index];
			if (sort_by == sort_logic.parameter) {
				if (sort_logic.primary){
					reverse = !sort_logic.reverse;
				}
				$scope.ticket_pagination.sort_array.splice(index,1);
			}
			else{
				sort_logic.primary = false;
			}

			index-=1;
		}

		$scope.ticket_pagination.sort_primary = sort_by;
		$scope.ticket_pagination.sort_primary_reverse = reverse;

		$scope.ticket_pagination.sort_array.unshift({
			'parameter':sort_by,
			'reverse':reverse,
			'primary':true
		});

		$scope.filter_tickets();
	}

	$scope.ticket_nav_change_creator_filter = function() {
		if($scope.filters.creator=="All") {
			$scope.filters.creator=$scope.user.user_id;
		}
		else{
			$scope.filters.creator="All"
		}
		$scope.paginate_tickets(0, true);
	}

	$scope.filter_tickets = function(){
		if ($scope.ticket_pagination.ticket_search !== "") {
			$scope.ticket_pagination.tickets = $scope.ticket_pagination.unfiltered_tickets.filter(
				function(ticket) {
					var found_result = false;

					['ticketNumber', 'subject'].forEach(function(key) {
						if (ticket && ticket[key] && ticket[key]['match']) {
							var escaped_ticket_search = angular.copy($scope.ticket_pagination.ticket_search);
							escaped_ticket_search=escaped_ticket_search.replaceAll("\\","\\\\");
							escaped_ticket_search=escaped_ticket_search.replaceAll("[","\\[");
							escaped_ticket_search=escaped_ticket_search.replaceAll("^","\\^");
							escaped_ticket_search=escaped_ticket_search.replaceAll("$","\\$");
							escaped_ticket_search=escaped_ticket_search.replaceAll(".","\\.");
							escaped_ticket_search=escaped_ticket_search.replaceAll("?","\\?");
							escaped_ticket_search=escaped_ticket_search.replaceAll("*","\\*");
							escaped_ticket_search=escaped_ticket_search.replaceAll("+","\\+");
							escaped_ticket_search=escaped_ticket_search.replaceAll("(","\\(");
							escaped_ticket_search=escaped_ticket_search.replaceAll(")","\\)");
							escaped_ticket_search=escaped_ticket_search.replaceAll("|","\\|");
							var result = null != ticket[key].match(new RegExp(escaped_ticket_search, "i"));
							if (result === true) {
								found_result = true;
							}
						}
					});
					return found_result;
				}
			);
		} else{
			$scope.ticket_pagination.tickets = angular.copy($scope.ticket_pagination.unfiltered_tickets);
		}

		// Honor the ticket sort array
		var index = $scope.ticket_pagination.sort_array.length-1;
		while(index >=0) {
			var sort_logic = $scope.ticket_pagination.sort_array[index];
			var sort_by = sort_logic.parameter;

			$scope.ticket_pagination.tickets.sort( function(a,b) {
				var property_a = a[sort_by];
				var property_b = b[sort_by];
				if (property_a > property_b ) {
					return 1
				}
				if (property_a < property_b) {
					return -1
				}
				return 0;

			});

			if(sort_logic.reverse) {
				$scope.ticket_pagination.tickets = $scope.ticket_pagination.tickets.reverse();
			}

			index-=1;

		}

		var current_ticket_index = ($scope.ticket_pagination.current_page-1) * $scope.ticket_pagination.page_size;
		while(current_ticket_index> 0){
			if (typeof $scope.ticket_pagination.tickets[current_ticket_index] != 'undefined'){
				break;
			}
			else {
				$scope.ticket_pagination.current_page--;
				current_ticket_index-=$scope.ticket_pagination.page_size;
			}
		}
		$scope.show_tickets();
	}

	$scope.sort_validation = function(sort_by) {
		const matches_sort_by = (element) => element['parameter'] == sort_by;
		var filter_index = $scope.ticket_pagination.sort_array.findIndex(matches_sort_by);
		if (filter_index < 0) {
			return true;
		}
		return $scope.ticket_pagination.sort_array[filter_index].reverse;
	}

	$scope.paginate_tickets = function(page, filter_change = false){
		// Check if tickets are already pulled (and confirm we're not at end of list)
		var ticket_index = (page * $scope.ticket_pagination.page_size)-1;

		if (filter_change == true) {
			$scope.ticket_pagination.current_page = 0;
			$scope.ticket_pagination.api_page = 0;
			$scope.ticket_nav_change_store_filter();
		}
		else if ( typeof $scope.ticket_pagination.tickets[ticket_index] != 'undefined'  ){
			$scope.ticket_pagination.current_page = page;
			$scope.show_tickets();
		}
		else if ( $scope.ticket_pagination.end_of_list  ){
			$scope.ticket_pagination.current_page = page;
			$scope.show_tickets();
		}
		else if( !($scope.ticket_pagination.end_of_list)) {
			$scope.load_tickets($scope.ticket_pagination.api_page+1);
		}
	}

	$scope.load_tickets = function(api_page) {
		var email = $scope.user.user_name;

		// Store Tickets filter only: We need to differentiate between utilizing Store as Group or DB.
		var db_group_id = typeof $scope.filters.store.id !== 'undefined' ? $scope.filters.store.id : 0;
		var db_id = 0;
		// Even if DB ID is set we want to ignore it if it's not intentional
		if ($scope.store_definition == 'dbs_only'){
			db_id = typeof $scope.filters.store.db_id !== 'undefined' ? $scope.filters.store.db_id : 0;
			if (db_id > 0) {
				$scope.filters.relevant_id = db_id;
			}
			else{
				$scope.filters.relevant_id = db_group_id;
			}
		}
		else{
			$scope.filters.relevant_id = db_group_id;
		}
		var tickets_email = $scope.filters.creator=="All" ? "" : email;

		if ($scope.fs_navigation_tab === 'tickets') {
			$scope.get_tickets(api_page, (db_group_id==-1), tickets_email, db_group_id, db_id);
		}
	}

	$scope.get_tickets = function(api_page, account_flag = false, email = "", db_group_id = 0, db_id = 0) {
		// Don't waste the resource if no filter is selected
		var non_filter_flag = !account_flag && email == '' && db_group_id == 0 && db_id == 0 && $scope.fs_ticket_number == '';

        if (non_filter_flag || $scope.fs_ticket_number === '0') {
			$scope.tickets = {};
			$scope.loading_tickets = false;
		}
		else {
			$scope.loading_tickets = true;

			var account_limit = account_flag ? 1 : 0 ;
			var selected_account_id = $scope.account_id;
			var final_modified_time = $scope.ticket_pagination.final_modified_time;
			var store_id = 0;

			var ticket_number = '';
			if($scope.fs_ticket_number !== '') {
				ticket_number = $scope.fs_ticket_number;
				// We ignore the selected account if honoring a specific ticket
				account_limit = 0;
			}

			var search_params = {
				'params': {
					'page': api_page,
					'status': $scope.params.status,
					'account_tickets': account_limit,
					'account_id': selected_account_id,
					'email':email,
					'db_group_id':db_group_id,
					'db_id':db_id,
					'final_modified_time':final_modified_time,
					'ticket_number':ticket_number
				}
			};

			if ($scope.store_definition == 'db_groups_only'){
				store_id = typeof $scope.filters.store.id !== 'undefined' ? $scope.filters.store.id : 0;
			}
			else{
				store_id = typeof $scope.filters.store.db_id !== 'undefined' ? $scope.filters.store.db_id : 0;
			}

			var current_page = angular.copy($scope.ticket_pagination.current_page);
			api_page = angular.copy($scope.ticket_pagination.api_page);
			$scope.pulling_tickets = true;

			$http.get('/api.php/zoho/my_tickets', search_params).then(function(response) {
				var data = response.data;
				$scope.ticket_pagination.unfiltered_tickets = $scope.ticket_pagination.unfiltered_tickets.concat(data.data.tickets); //TODO needs to merge arrays
				if ($scope.ticket_pagination.ticket_search !== "") {

					$scope.ticket_pagination.tickets = $scope.ticket_pagination.unfiltered_tickets.filter(
						function(ticket) {
							var found_result = false;

							['ticketNumber', 'subject'].forEach(function(key) {
								if (ticket && ticket[key] && ticket[key]['match']) {
									var result = null != ticket[key].match(new RegExp($scope.ticket_pagination.ticket_search, "i"));
									if (result === true) {
										found_result = true;
									}
								}
							});
							return found_result;
						}
					);
				}
				else{
					$scope.ticket_pagination.tickets = angular.copy($scope.ticket_pagination.unfiltered_tickets);
				}
				$scope.ticket_pagination.current_page= ++current_page;
				$scope.ticket_pagination.api_page = ++api_page;
				$scope.ticket_pagination.end_of_list = response.data.data.end_of_list;
				$scope.ticket_pagination.final_modified_time = response.data.data.final_modified_time;
				$scope.pulling_tickets = false;
				$scope.show_tickets();
				// $scope.tickets = data;
				// $scope.loading_tickets = false;
			}, function(response) {
				var data = response.data;
				// $scope.tickets = {};
				$scope.loading_tickets = false;
				$scope.pulling_tickets = false;
				fdxUI.showToastError('There was an error retrieving tickets');
			});
		}
	};

	$scope.show_tickets = function(){
		var initial_ticket_index = ($scope.ticket_pagination.current_page-1) * $scope.ticket_pagination.page_size;
		$scope.tickets = [];
		for(var i = initial_ticket_index; i< initial_ticket_index + $scope.ticket_pagination.page_size; i++) {
			if (typeof $scope.ticket_pagination.tickets[i] != 'undefined'){
				$scope.tickets.push($scope.ticket_pagination.tickets[i]);
			}
		}
		// If search is cycling to re-run - this will allow it to complete by resetting
		$scope.ticket_pagination.ticket_pull_index = 0;
		$scope.loading_tickets = false;

		if ($scope.fs_ticket_number!=='' ) {
			if(!$scope.tickets.length) {
				FeedSupportStateService.setTicketForbidden(true);
			}
			else if (!($scope.tickets[0].show_details === true)){
				$scope.open_ticket($scope.tickets[0]);
				FeedSupportStateService.setTicketForbidden(false);
			}
			else {
				FeedSupportStateService.setTicketForbidden(false);
			}

		}
	}

	// Individual Ticket Functions

	$scope.open_ticket = function(ticket) {
		if (ticket.show_details === true) {
			ticket.show_details = false;
			return;
		}

		$scope.loading_conversations[ticket.zohoTicketId] = true;
		ticket.show_details = true;

		$http.get('/api.php/accounts/'+ $scope.account_id +'/zoho/my_tickets/'+ ticket.zohoTicketId)
			.then(function(response) {
				var resp = response.data;
				if (typeof resp['data']['file_attachments'] != 'undefined') {
					$scope.attachments[ticket.zohoTicketId] = resp['data']['file_attachments'];
					delete resp['data']['file_attachments'];
				}
				else{
					delete $scope.attachments[ticket.zohoTicketId];
				}
				// Remove #original_sender tag in display
				for (var i = 0; i<Object.keys(resp.data).length; i++) {
					if(resp.data[i].content && (resp.data[i].content.startsWith("<div>#original_sender") || resp.data[i].content.startsWith("#original_sender"))) {
						// Presently the content is wrapped in a div container. In case this auto structure ever changes (or content comes in unaltered) catch both cases.
						var div_flag = resp.data[i].content.startsWith("<div>#original_sender");
						// Remove everything before the closing curly bracket around the email
						resp.data[i].content= resp.data[i].content.substring(resp.data[i].content.indexOf("}")+1);
						// Also remove the <br> tag - this appears to change from how entered from <br> to <br /> - just look for the closing bracket in case
						resp.data[i].content= resp.data[i].content.substring(resp.data[i].content.indexOf(">")+1);
						// Restore the <div> if we originally found one.
						if (div_flag) {
							resp.data[i].content= "<div>"+resp.data[i].content;
						}
					}
				}
				$scope.conversations[ticket.zohoTicketId] = resp;
				$scope.loading_conversations[ticket.zohoTicketId] = false;
			}, function(response) {
				var data = response.data;
				$scope.conversations[ticket.zohoTicketId] = [];
				$scope.loading_conversations[ticket.zohoTicketId] = false;
				fdxUI.showToastError('There was an error retrieving responses for this ticket.');
			});
	};

	$scope.change_priority = function(ticket) {

		var params = {};
		ticket.working_priority_menu = true;
		params["priority"] = ticket.priority;
		$http.put('/api.php/accounts/'+$scope.account_id+'/zoho/tickets/'+ticket.zohoTicketId, params)
			.then(function(response) {
				var data = response.data;
				ticket.working_priority_menu = false;
				ticket.show_priority_menu = false;
			}, function(response) {
				var data = response.data;
				ticket.working_priority_menu = false;
				fdxUI.showToastError('There was an error updating your ticket!');
			});
	}

	$scope.respond_to_ticket = function(ticket, close_ticket) {
		var empty_message_flag =
			typeof $scope.editors['response_'+ ticket.zohoTicketId] == 'undefined'
			|| $scope.editors['response_'+ ticket.zohoTicketId] == "";
		if(empty_message_flag){
			var error_message = "Message is required to reply to ticket.";
			if(close_ticket) {
				error_message = "Message is required to close ticket.";
			}
			 fdxUI.showToastError(error_message);
			return;
		}
		ticket.show_reply_flag = false;

		var ticket_id = ticket['id'];
		var external_ticket_id = '';

		for(var cf_ticket in $scope.ticket_pagination.tickets){
			if($scope.ticket_pagination.tickets.hasOwnProperty(cf_ticket)){

				var has_external_ticket = $scope.ticket_pagination.tickets[cf_ticket] == ticket &&
					$scope.ticket_pagination.tickets[cf_ticket]['id'];

				if (has_external_ticket) {
					external_ticket_id = $scope.ticket_pagination.tickets[cf_ticket]['id'];
				}
			}
		}

		var response_content = $scope.editors['response_'+ ticket.zohoTicketId];
		if(close_ticket === true) {
			response_content += "<br><font color=\"red\">The customer has requested that this ticket be closed.</font>";
		}

		ticket.pending_count = Object.keys($scope.conversations[ticket.zohoTicketId].data).length;
		$http.post('/api.php/accounts/'+ $scope.account_id +'/zoho/reply_to_ticket', {
			'close_ticket': close_ticket,
			'content': response_content,
			'email': ticket['email'],
			'ticket_id': ticket.zohoTicketId,
			'ticket_number':ticket.ticketNumber,
			'ticket_subject':ticket.subject,
			'external_ticket_id':external_ticket_id
		}).then(function(response) {
			var data = response.data;
			// if(close_ticket === true) {
			// 	ticket.status = 'Closed';
			// }
			if(typeof $scope.conversations[ticket.zohoTicketId].pending_data == 'undefined'){
				$scope.conversations[ticket.zohoTicketId].pending_data = [];
			}
			$scope.conversations[ticket.zohoTicketId].pending_data.push({
				'content':$scope.editors['response_'+ ticket.zohoTicketId],
				'author':{'name':data.from_name},
				'createdAt':data.data.created_date,

			});
			$scope.loading_conversations[ticket.zohoTicketId] = true;
			ticket.show_details = true;

			delete $scope.editors['response_'+ ticket.zohoTicketId];

			if(typeof $scope.pending_files['file_'+ticket.zohoTicketId] !== 'undefined' && ticket.show_upload_flag) {
				$scope.upload_pending_file(ticket, true);
			}
			else{
				$scope.wait_for_answer(ticket);
			}


			// ticket.show_details = false;
			// $scope.editors['response_'+ ticket.zohoTicketId] = '';
			// $scope.open_ticket(ticket);
		}, function(response) {
			var data = response.data;
			fdxUI.showToastError('There was an error replying to the ticket!');
		});
	};

	$scope.wait_for_answer = function(ticket){
		$http.get('/api.php/accounts/'+ $scope.account_id +'/zoho/my_tickets/'+ ticket.zohoTicketId).then(function(response) {
			var resp = response.data;

			if (typeof resp['data']['file_attachments'] != 'undefined') {
				$scope.attachments[ticket.zohoTicketId] = resp['data']['file_attachments'];
				delete resp['data']['file_attachments'];
			}
			else{
				delete $scope.attachments[ticket.zohoTicketId];
			}

			// There is an "attachments" and "prototype" element inside the response object. We remove attachments. These are the reason for the +1
			var new_response_count = Object.keys(resp.data).length -1;
			if(new_response_count >= ticket.pending_count ){
				$scope.conversations[ticket.zohoTicketId] = resp;
				$scope.loading_conversations[ticket.zohoTicketId] = false;
				$scope.conversations[ticket.zohoTicketId].pending_data = [];
				//$scope.loading_conversations[ticket.zohoTicketId] = false;
			}
			else{
				setTimeout(function () {
					$scope.wait_for_answer(ticket);
				}, 1000);
			}
		}, function(response) {
			var data = response.data;
			$scope.conversations[ticket.zohoTicketId] = [];
			$scope.loading_conversations[ticket.zohoTicketId] = false;
			fdxUI.showToastError('There was an error retrieving responses for this ticket.');
		});
	}

	$scope.reload_ticket = function(ticket){
		var rand_str = (new Date()).getTime();
		var config = {cache:false, rand:rand_str};
		$http.get('/api.php/accounts/'+ $scope.account_id +'/zoho/my_tickets/'+ ticket.zohoTicketId, config).then(function(response) {
			var resp = response.data;

			if (typeof resp['data']['file_attachments'] != 'undefined') {
				$scope.attachments[ticket.zohoTicketId] = resp['data']['file_attachments'];
				delete resp['data']['file_attachments'];
			}
			else{
				delete $scope.attachments[ticket.zohoTicketId];
			}
			$scope.conversations[ticket.zohoTicketId] = resp;
			$scope.loading_conversations[ticket.zohoTicketId] = false;
			$scope.conversations[ticket.zohoTicketId].pending_data = [];

		}, function(response) {
			var data = response.data;
			$scope.conversations[ticket.zohoTicketId] = [];
			$scope.loading_conversations[ticket.zohoTicketId] = false;
			fdxUI.showToastError('There was an error retrieving responses for this ticket.');
		});
	}

	$scope.download_attachment = function(ticket, attachment) {
		const iframe = document.createElement('iframe');
		iframe.style.display = 'none';
		document.body.appendChild(iframe);
		iframe.src = '/api.php/accounts/'+ $scope.account_id +'/zoho/my_tickets/'+ ticket.zohoTicketId +'/attachments/'+ attachment.attachmentId +'?attachment_name='+ attachment.name;
	}

	$scope.upload_pending_file = function(ticket, reply_flag = false) {
		var missing_file_flag =
			typeof $scope.pending_files['file_'+ticket.zohoTicketId] == 'undefined'
			|| $scope.pending_files['file_'+ticket.zohoTicketId] == "";
		if (missing_file_flag) {
			if (!reply_flag){
				 fdxUI.showToastError("No file to upload.");
			}
			return;
		}

		ticket.show_upload_flag = false;

		var pending_file = $scope.pending_files['file_'+ticket.zohoTicketId];

		// We need to reload but we're not expecting a larger number of results than before.
		ticket.pending_count = Object.keys($scope.conversations[ticket.zohoTicketId].data).length-1;

		$scope.loading_conversations[ticket.zohoTicketId] = true;
		ticket.show_details = true;

		const ticket_params = {};
		ticket_params['ticket_id'] = ticket.zohoTicketId;

		$upload.upload({
			url: '/api.php/accounts/' + $scope.account_id + '/zoho/file_attachments',
			method: 'POST',
			data: ticket_params,
			file: pending_file
		}).then(function(response){
			delete $scope.pending_files['file_'+ticket.zohoTicketId];
			if (!reply_flag){
				$scope.reload_ticket(ticket);
			}
			else {
				$scope.wait_for_answer(ticket);
			}

		},{ function(response){
			if(!reply_flag) {
				 fdxUI.showToastError("File Upload Failed");
			}
			else {
				 fdxUI.showToastError("File Upload Failed (Reply was successfully sent)");
			}

			if (!reply_flag){
				$scope.reload_ticket(ticket);
			}
			else {
				$scope.wait_for_answer(ticket);
			}
		}});
	}

	$scope.establish_entities = function(update_entities_flag = false){
		if (!$scope.entities.db_group_populated || update_entities_flag){

			var fsPromise = FeedSupportStateService.getStores($scope.account_id, 0, true).then((stores)=>{
				if(typeof stores == 'undefined' || stores == null) {
					stores = [];
				}
				stores = $scope.honor_account_access(stores);

				if(stores.length > 100) {
					$scope.store_filter_dropdown.load_flag = true;
					$scope.feedsupport_navbar.store_select_dropdown = [];
				}
				else {
					$scope.store_filter_dropdown.load_flag = false;
					$scope.feedsupport_navbar.store_select_dropdown = stores;

					$scope.entities.db_groups = stores;

				}

				if ($scope.feedsupport_navbar.account_ticket_access && !$scope.ebay_minimal_ui_flag) {
					if ($scope.feedsupport_navbar.account_ticket_access && typeof $scope.filters.store.id == 'undefined' && $scope.fs_navigation_tab == 'tickets' && !$scope.feedsupport_navbar.default_load_all_stores) {
						$scope.filters.store = stores[0];
						$scope.paginate_tickets(1,true);
						$scope.feedsupport_navbar.default_load_all_stores = true;
					}
					else if($scope.fs_ticket_number !== '') {
						$scope.paginate_tickets(1,true);
					}
				}
				else if($scope.fs_ticket_number !== '') {
					$scope.paginate_tickets(1,true);
				}



				$scope.entities.db_group_populated = true;
			});
		}
	}

	$scope.dropdownFilterOpen = function(isOpen){
		if (isOpen) {
			$scope.set_filter_stores();
		}
		else {
			$scope.unset_filter_stores();
		}
	}

	$scope.set_filter_stores = function() {
		if($scope.store_filter_dropdown.load_flag) {
			var fsPromise = FeedSupportStateService.getStores($scope.account_id, 0, true).then((stores)=>{
				if(typeof stores == 'undefined' || stores == null) {
					stores = [];
				}
				$scope.store_filter_dropdown.stores_list= $scope.honor_account_access(stores);

			});
		}
		else{
			$scope.store_filter_dropdown.stores_list = $scope.feedsupport_navbar.store_select_dropdown;
		}


	}

	$scope.unset_filter_stores = function() {
		$scope.store_filter_dropdown.stores_list = [];
	}

	$scope.honor_account_access = function(incomplete_list) {
		if ($scope.feedsupport_navbar.account_ticket_access && !$scope.ebay_minimal_ui_flag) {
			incomplete_list.splice(0, 0, {
				active: true,
				display_name: "All",
				id: "-1",
				name: "",
				lastflag:"1"
			});
		}
		return incomplete_list;

	}


	$scope.checkJSON = function(string){
		try {
	        JSON.parse(string);
	    } catch (e) {
	        return false;
	    }
	    return true;
	}


	$scope.priorities=[
		{'value':'Standard','display_name':'Standard'},
		{'value':'Urgent','display_name':'Urgent'}
	];

	$scope.statuses=[
		{'value':'','display_name':'All'},
		{'value':'Open','display_name':'Open'},
		// {'value':'On Hold','display_name':'On Hold'},
		{'value':'Closed','display_name':'Closed'},
		// {'value':'Action Required','display_name':'Action Required'},
		// {'value':'Build In Progress','display_name':'Build In Progress'},
		// {'value':'Data Review In Progress','display_name':'Data Review In Progress'},
		// {'value':'Delayed By Client','display_name':'Delayed By Client'},
		// {'value':'Escalated','display_name':'Escalated'},
		// {'value':'Handed Off','display_name':'Handed Off'},
		// {'value':'Import Setup','display_name':'Import Setup'},
		// {'value':'Information Needed','display_name':'Information Needed'},
		// {'value':'Internal Escalation','display_name':'Internal Escalation'},
		// {'value':'Live - Under Review','display_name':'Live - Under Review'},
		// {'value':'Monitoring','display_name':'Monitoring'},
		// {'value':'Onboarding In Progress','display_name':'Onboarding In Progress'},
		// {'value':'Onboarding Started','display_name':'Onboarding Started'},
		// {'value':'Post-QA Optimizations','display_name':'Post-QA Optimizations'},
		// {'value':'QA In Progress','display_name':'QA In Progress'},
		// {'value':'Ready for QA','display_name':'Ready for QA'},
		// {'value':'Ready to Go Live','display_name':'Ready to Go Live'},
		// {'value':'Reopened','display_name':'Reopened'},
		// {'value':'Support in Progress','display_name':'Support in Progress'},
		// {'value':'Waiting for Confirmation','display_name':'Waiting for Confirmation'}
	];

	$scope.get_store_definition = function() {
		var fsPromise = FeedSupportStateService.getStoreDefinition($scope.account_id).then((definition)=>{
			$scope.store_definition = definition;
		});
	}



	$scope.delete_attachment_modal = function (ticket, attachment) {
		ModalService
			.showDangerConfirmationModal(
				'Confirm Attachment Delete',
				`Are you sure you'd like to delete ${attachment.name}?`,
				'Delete'
			)
			.then(
				() => {
					$scope.loading_conversations[ticket.zohoTicketId] = true;
					ticket.show_details = true;

					// We need to reload but we're not expecting a larger number of results than before.
					ticket.pending_count = Object.keys($scope.conversations[ticket.zohoTicketId].data).length - 1;

					$http
						.delete('/api.php/accounts/' + $scope.account_id + '/zoho/my_tickets/' + ticket.zohoTicketId + '/attachments/' + attachment.attachmentId)
						.then(
							function (response) {
								$scope.reload_ticket(ticket);
							}, function (response) {
								fdxUI.showToastError('There was an error deleting this attachment');
								$scope.reload_ticket(ticket);
							}
						);
				},
				angular.noop
			);
	}

	$scope.ok = function() {
		$scope.$close();
	}
}]);
