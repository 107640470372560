import { UIRouterState } from '@ajs/ajs-upgraded-providers';
import { AccountModel } from '@ajs/models/account';
import AppStateService from '@ajs/services/AppStateService';
import ImpersonateUserService from '@ajs/services/ImpersonateUserService';
import FdxUI from '@ajs/services/fdxUI';
import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getEncodedUrlPath } from '@app/core/functions';
import { API_DATE_TIME_FORMAT } from '@app/core/models/constants/date-time';
import { WINDOW } from '@app/core/providers/window.provider';
import { DateTimeService } from '@app/core/services/date-time.service';
import { FdxBlockUIService } from '@app/core/services/fdx-block-ui.service';
import { FdxUtilsService } from '@app/core/services/fdx-utils.service';
import { LinkService } from '@app/core/services/link.service';
import { DbModel } from '@app/databases/models/db.model';
import { AbstractAppMenuComponent } from '@app/modules/nav/components/abstract-app-menu/abstract-app-menu.controller';
import ReleaseNotesService from '@app/release-notes/services/release-notes.service';
import { Grant } from '@app/user/models/user-data.model';
import { InvitationsService } from '@app/user/services/invitations.service';
import { UserDataService } from '@app/user/services/user-data.service';
import { IconDefinition, faBellRing, faBoltLightning, faChartColumn, faCircle, faFileExport, faFileImport, faHome, faTable } from '@fortawesome/pro-solid-svg-icons';
import { StateService } from '@uirouter/angularjs';
import { filter, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'fdx-app-main-menu',
    templateUrl: './app-main-menu.component.html',
    styleUrls: ['./app-main-menu.component.scss']
})
export class AppMainMenuComponent extends AbstractAppMenuComponent implements OnInit {
    @HostBinding('class.sticky-top')
    readonly stickyTop: boolean = true;

    readonly dashboardsTabIcon: IconDefinition = faHome;
    readonly dataSourcesTabIcon: IconDefinition = faFileImport;
    readonly dataTabIcon: IconDefinition = faTable;
    readonly transformersTabIcon: IconDefinition = faBoltLightning;
    readonly exportsTabIcon: IconDefinition = faFileExport;
    readonly reportsTabIcon: IconDefinition = faChartColumn;
    readonly notificationIcon: IconDefinition = faBellRing;
    readonly circleIcon: IconDefinition = faCircle;

    get account(): AccountModel {
        return this.appStateService.getAccount();
    }

    get accountSelected(): boolean {
        return this.appStateService.getAccountId() !== null;
    }

    get database(): DbModel {
        return this.appStateService.getDatabase();
    }

    get databaseId(): string | null {
        return this.appStateService.getDatabaseId();
    }

    get databaseIsGrouped(): boolean {
        const database = this.database;

        if (!database) {
            return false;
        }

        const dbGroupId = database.db_group_id;

        return typeof (dbGroupId) === 'number' && dbGroupId !== 0;
    }

    get fullDbGroupAccess(): boolean {
        const database = this.database;

        if (!database) {
            return false;
        }

        return database.full_db_group_access;
    }

    get isEventSync(): boolean {
        return this.database?.event_sync === '1';
    }

    get isDbDeleted(): boolean {
        return this.database?.visibility === '0';
    }

    get dbDeletedAt(): string | null {
        return this.database?.deleted_at || null;
    }

    get isDbPaused(): boolean {
        return this.database?.paused === '1';
    }

    /**
     * TODO: Replace this getter with a `BehaviorSubject` subscription once `AppService` is upgraded
     * to an Angular service.
     */
    get dbPausedAt(): string {
        let formattedPausedTime: string = 'is paused';

        const pausedAt = this.database?.paused_at;

        if (pausedAt && pausedAt !== '0000-00-00 00:00:00') {
            const datetime = this.dateTimeService.getFormattedTime(
                pausedAt,
                'MMMM D, YYYY HH:mm:ss A',
                API_DATE_TIME_FORMAT,
                this.dateTimeService.getTimezone()
            );

            const timeZoneCode = this.dateTimeService.getTimezoneCode();

            formattedPausedTime = 'was paused at ' + datetime + ' ' + timeZoneCode;
        }

        return formattedPausedTime;
    }

    get isPrimaryUser(): boolean {
        return this.appStateService.isPrimaryUser();
    }

    get isUserInternal(): boolean {
        return this.user?.isInternal();
    }

    get logo(): string {
        const date = new Date();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        let path: string = this.account?.logo;

        if (!path) {
            if (month === 2 && (day >= 10 && day <= 14)) {
                // Feb 10-14th - valentines.gif
                path = 'holiday/holiday_logo_valentines_loop.gif';
            } else if (month === 3 && (day >= 11 && day <= 17)) {
                // March 11-17 - st_patricks.gif
                path = 'holiday/holiday_logo_stpatricks_loop.gif';
            } else if (month === 5 && day === 4) {
                //  May 4th - yoda.gif
                path = 'holiday/holiday_logo_may4th_loop.gif';
            } else if (month === 10 && (day >= 21 && day <= 31)) {
                // October 21-31: halloween.gif
                path = 'holiday/holiday_logo_halloween_loop.gif';
            } else if (month === 11) {
                // November 1st - Nov 30: thanksgiving.gif
                path = 'holiday/holiday_logo_thanksgiving_loop.gif';
            } else if (month === 12) {
                // Dec 1 - 31st holiday_lights.gif
                path = 'holiday/holiday_logo_bulblights_loop.gif';
            } else {
                // if it's not holiday time:
                path = 'feedonomics/feedonomics-logomark-white.svg';
            }
        }

        return `/app/images/logos/${path}`;
    }

    get supportOnly(): boolean {
        return this.appStateService.getAccountPermissions() === 'support_only';
    }

    get readOnlyNoSupport(): boolean {
        return this.appStateService.getAccountPermissions() === 'read_only_no_support';
    }

    get hasNotifications(): boolean {
        return this.accountSelected && this.user && this.hasUnreadReleaseNotes;
    }

    get hasUnreadReleaseNotes(): boolean {
        return this.releaseNotesService.unread;
    }

    get showFeedAlerts(): boolean {
        return (this.hasGrant(Grant.FeedAlerts) || !this.isUserInternal) && !this.supportOnly;
    }

    get showFeedSupport(): boolean {
        return (this.hasGrant(Grant.FeedSupport) || !this.isUserInternal) && !this.readOnlyNoSupport;
    }

    get showFeedSupportNew(): boolean {
        return !this.isUserInternal && !this.readOnlyNoSupport &&
            (this.accountHasFeature('new_feedsupport', 'enabled') ||
            this.userHasFeature('new_feedsupport', 'enabled'));
    }

    get feedSupportLink(): string[] {
        if (this.showFeedSupportNew) {
            return this.linkService.feedSupportNewLink;
        }

        return this.linkService.feedSupportLink;
    }

    get showFeedAMP(): boolean {
        return this.hasGrant(Grant.FeedAMP) || (!this.isUserInternal && !this.supportOnly);
    }

    get showFeedStatus(): boolean {
        return (this.hasGrant(Grant.FeedStatus) || !this.isUserInternal) &&
            (!this.accountHasFeature('lock_feedstatus_dashboard', 'enabled') &&
                (
                    this.isPrivacyLevelAtLeastAnalyst ||
                    this.accountHasFeature('feedstatus_beta', 'enabled') ||
                    this.userHasFeature('feedstatus_beta', 'enabled')
                )
            ); // TODO: check user permission for write or (rakuten account id && read)
    }

    get showFeedGrader(): boolean {
        return this.isPrivacyLevelAtLeastAnalyst || this.userHasFeature('feedgrader', 'enabled') || this.accountHasFeature('feedgrader', 'enabled');
    }

    get showStoreSummary(): boolean {
        return this.isUserInternal || this.accountHasFeature('store_summary', 'enabled');
    }

    get impersonatingUserName(): string {
        return this.impersonateUserService.getUser()?.impersonated_user_name;
    }

    get currentPath(): string {
        return getEncodedUrlPath() ?? '';
    }

    constructor(
        @Inject(WINDOW) protected readonly window: Window,
        @Inject(UIRouterState) protected readonly $state: StateService,   // Temporary for use with Ebay Selection and User Access Control pages being on AngularJS router
        protected readonly linkService: LinkService,
        protected readonly appStateService: AppStateService,
        protected readonly dateTimeService: DateTimeService,
        protected readonly fdxBlockUIService: FdxBlockUIService,
        protected readonly fdxUI: FdxUI,
        protected readonly fdxUtils: FdxUtilsService,
        protected readonly impersonateUserService: ImpersonateUserService,   // TODO: Upgrade to Angular
        protected readonly invitationsService: InvitationsService,
        protected readonly userDataService: UserDataService,
        protected readonly router: Router,
        private readonly releaseNotesService: ReleaseNotesService
    ) {
        super($state, linkService, appStateService, fdxBlockUIService, fdxUI, fdxUtils, impersonateUserService, invitationsService, userDataService, router, window);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.appStateService.userLoaded$
            .pipe(
                filter((value) => Boolean(value)),
                tap(() => this.releaseNotesService.checkUnread()),
                takeUntil(this.unsubscribe$)
            ).subscribe();
    }

    removeImpersonatedUser(): void {
        this.impersonateUserService.setUser(null);
    }
}
